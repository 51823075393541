import './App.css';
import Window from './components/Window';

function App() {
  return (
    <>
      <Window/>
    </>
  );
}

export default App;
