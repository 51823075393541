import React, { useEffect, useState } from 'react'
import './ModuleCard.css'

interface ModuleCardProps {
    module: any;
    setSelected: any;
}

export default function ModuleCard(props: ModuleCardProps) {
  const [state,setState] = useState(props.module.state());

  useEffect(() => {
    props.module.state(state);
  }, [state])
  

  return (
    <div className='module-card'>
      <div className="module-card-head">
        <span className='module-card-title'> {props.module.key()} </span>
        <div className="module-card-logo"/>
      </div>
      <hr style={{marginTop:'-2px',height:'2px',border:'none',color:'rgba(255,255,255,0.06)',backgroundColor:'rgba(255,255,255,0.19)'}}/>    
      <div className="module-card-body">
        <span className="module-card-description">
          {props.module.description()}
        </span>
        <div className="module-card-btn-group">
          <button onClick={() => { setState(!state) }} className={`btn ${state ? 'module-card-enabled-btn' : 'module-card-disabled-btn'}`}>
            {state ? "Enabled" : "Disabled"}
          </button>
          <button onClick={() => { props.setSelected(props.module) }}>Options</button>
        </div>
      </div>
    </div>
  )
}
