import React, { useState } from 'react'
import './Window.css'
import ModulesTab from '../tabs/ModulesTab';
import OptionsTab from '../tabs/OptionsTab';
import 'boxicons'

export default function Window() {
    const tabs = [{
      id: 'Modules',
      icon: <i className='bx bx-home-alt'></i>,
      page: <ModulesTab />
    },
    {
      id: 'Options',
      icon: <i className='bx bx-cog'></i>,
      page: <OptionsTab />
    },]

    const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div className="window">
        <div className='tabs'>
            <h1 style={{marginLeft:'50px',marginTop:'100px'}}>Aetherium</h1>
            <div className="tab-wrapper">
            {tabs.map((obj) => (
                <h3 className='tab' style={{backgroundColor: (currentTab.id === obj.id ? 'rgba(255,255,255,0.300)' : '') }} onClick={() => setCurrentTab(obj)}>{obj.icon} <span style={{marginLeft:'15px'}}>{obj.id}</span></h3>
            ))}
            </div>
        </div>
        <div className='content'>
            {currentTab.page}
        </div>
    </div>
  )
}
