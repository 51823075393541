
import React, { useEffect, useRef, useState } from 'react'
import ModuleCard from '../components/module/ModuleCard';
import 'boxicons'
import '../components/setting/Setting.css'
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import ColorSetting from '../components/setting/ColorPicker';
import SliderSetting from '../components/setting/SliderSetting';


export default function ModulesTab() {
    const ArrayList = eval('jvm').get('java.util.ArrayList')
    const moduleManager = eval('aetherium').getModuleManager();
    const modList = new ArrayList(moduleManager.modules().values());

    const [filterText, setFilterText] = useState("");
    const [selected, setSelected] = useState(null);

    const modules = [];
    const [settings, setSettings] = useState<any[]>([]);


    useEffect(() => {
        setFilterText("");

        if (selected) {
            sel = selected;
            const setList = new ArrayList(sel.values().values());
            console.log(sel);

            const newSettings = [];
            for (let i = 0; i < setList.size(); i++) {
                newSettings.push(setList.get(i));
                console.log(setList.get(i));
            }
            setSettings(newSettings);
        }
    }, [selected]);


    for (let i = 0; i < modList.size(); i++) {
        modules.push(modList.get(i));
    }

    const filteredItems = modules.filter(
        item =>
            item.description().toLowerCase().includes(filterText) ||
            item.key().toLowerCase().includes(filterText)
    );


    const filteredSet = settings.filter(
        item =>
            item.name().toLowerCase().includes(filterText)
    );

    const itemsToUse = filterText ? filteredItems : modules;
    const setToUse = filterText ? filteredSet : settings;
    var sel: any = selected;

    return (
        <div>
            <div className="titlebar">
                <div className="wrapper">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={filterText}
                        className='module-search-bar'
                        onChange={e => setFilterText(e.target.value.toLocaleLowerCase())}
                    />
                </div>
                <span className="titlebar-title">
                    Modules
                </span>
            </div>

            {sel ?
                (
                    <>
                        <div className="setting-title">
                            <button className='icon-btn' onClick={() => setSelected(null)}>
                                <i className='bx bx-left-arrow-alt'></i>
                            </button>
                            <h2>Options for {sel.key()}</h2>
                        </div>
                        <div className='setting-wrap'>

                            {setToUse.map((setting: any) => (
                                <div className="setting-component">
                                    <div className="setting-name">{setting.name()}</div>
                                    {setting.type() === 'boolean' && (
                                        <input className="switch" id={setting.name()} defaultChecked={setting.value()} type="checkbox" onChange={(ev) => { setting.value(ev.target.checked) }} />
                                    )}
                                    {setting.type() === 'color' && (
                                        <ColorSetting setting={setting}/>
                                    )}
                                    {setting.type() === 'float' && (
                                        <SliderSetting setting={setting}/>
                                    )} 
                                    </div>
                            ))}
                        </div>
                    </>
                ) :
                (
                    <div className='module-wrap'>
                        {itemsToUse.map((module) => (
                            <ModuleCard setSelected={setSelected} module={module} />
                        ))}
                    </div>
                )}
        </div>
    )
}
