import { useEffect, useRef } from 'react'
import 'toolcool-color-picker';
const Color: any = eval('jvm').get('java.awt.Color');

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'toolcool-color-picker': any;
        }
    }
}

export default function ColorSetting(props: { setting: any; }) {
    const colorPickerRef = useRef<HTMLElement>();

    useEffect(() => {
        const colorPicker = colorPickerRef.current;

        const onColorChange = (evt: Event) => {
            const customEvent = evt as CustomEvent;
            props.setting.value(new Color(customEvent.detail.color.r, customEvent.detail.color.g, customEvent.detail.color.b, Math.round(customEvent.detail.color.a * 255)))
        };

        colorPicker?.addEventListener('change', onColorChange);

        return () => {
            colorPicker?.removeEventListener('change', onColorChange);
        };
    }, []);

    return (
        <toolcool-color-picker ref={ colorPickerRef }  color={`rgba(${props.setting.value().getRed()},${props.setting.value().getBlue()},${props.setting.value().getGreen()},${props.setting.value().getAlpha()/255})`} />
    )
}
