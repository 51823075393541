import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-smooth-range-input';
import { RangeSlider } from 'toolcool-range-slider';

export default function SliderSetting(props: { setting: any; }) {
    const [value, setValue] = useState(props.setting.value());

    const onChange = (evt: any) => {
        console.log(evt.target.value)
        props.setting.value(parseFloat(evt.target.value));
        setValue(props.setting.value())
    };

    return (
        <div>
            <span className="label">{value}</span>
            <input className='slider' type="range" onChange={onChange} defaultValue={props.setting.value()} min={props.setting.min()} max={props.setting.max()} />         
        </div>
    )
}
